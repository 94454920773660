<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0"><feather-icon
                icon="ArrowLeftIcon"
                size="24"
                class="mr-2"
                @click="goBack"
              />Expense</h2>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <span aria-current="location"> Add </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer ref="simpleRules">
      <b-form method="post" @submit="expenseAdd">
        <b-card>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Hatch Id<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Hatch Id"
                  rules="required"
                >
                  <v-select
                    v-model="selected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="hatch_id"
                    :options="hatchList"
                    @input="getHatchID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Rent<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Rent"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.rent"
                      placeholder="Rent"
                      type="text"
                      name="rent"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Fuel<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Fuel"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.fuel"
                      placeholder="Fuel"
                      type="text"
                      name="fuel"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="Electricity" label="Electricity">
                <template v-slot:label>
                  Electricity<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Electricity"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.electricity"
                      placeholder="Electricity"
                      type="text"
                      name="electricity"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Labour Salary<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Labour Salary"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.labour_sal"
                      placeholder="Labour Salary"
                      type="text"
                      name="labour_sal"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Medical<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Medical"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.medical"
                      placeholder="Medical"
                      type="text"
                      name="medical"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Water<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Water"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.water"
                      placeholder="Water"
                      type="text"
                      name="water"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Other<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Other"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.other"
                      placeholder="Other"
                      type="text"
                      name="other"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- submit and reset -->
          <b-col>
            <b-button
              type="submit"
              variant="primary"
              value="Submit"
              class="mr-1"
            >
              Submit
            </b-button>
          </b-col>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import * as Vue from "vue";

import { required, email } from "@validations";
import vSelect from "vue-select";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormSelect,
  BFormDatepicker,
  BInputGroupAppend,
} from "bootstrap-vue";
import axiosIns from "@/libs/axios";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BFormSelect,
    BFormDatepicker,
    vSelect,
  },

  data() {
    return {
      selected: null,
      FormData: {
        rent: null,
        fuel: null,
        electricity: null,
        labour_sal: null,
        medical: null,
        water: null,
        other: null,
      },
      hatchList: [],
      required,
      email,
    };
  },
 created: function (){
    this.getData();
  },
  methods: {
      goBack() {
      this.$router.go(-1);
    },
    getData() {
      let hatcheryId = this.$route.params.hatcheryId;
      axiosIns
        .get(`web/hatcheries/${hatcheryId}/post-hatch-list`)
        .then((response) => {
          this.hatchList = response.data.postHatchList;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    getHatchID(event) {
      this.selected = event.hatch_id;
    },
    expenseAdd(e) {
      e.preventDefault();
      const hatchery_id = this.$route.params.hatcheryId;
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          var data = new FormData();
          data.append("rent", this.FormData.rent);
          data.append("fuel", this.FormData.fuel);
          data.append("electricity", this.FormData.electricity);
          data.append("labour_sal", this.FormData.labour_sal);
          data.append("medical", this.FormData.medical);
          data.append("water", this.FormData.water);
          data.append("other", this.FormData.other);
          data.append("hatch_id", this.selected);
          data.append("hatchery_id", hatchery_id);
          axiosIns
            .post(`web/hatchery/${hatchery_id}/addExpense`, data)
            .then((res) => {
              res;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `data Successfully Added `,
                },
              });
              setTimeout(
                this.$router.push({
                  name: `apps-expense-list`,
                }),
                3000
              );
            })
            .catch((error) => {
              error.response;
              const data = error.response.data.message;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: data,
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
